import { Message } from 'element-ui';
const showSuccessTip = (tip) => {
  Message.success({
    message:tip,
  });
}
const showErrorTip = (tip = '接口出现错误,请稍后重试') =>{
  Message({
    type:'error',
    message:tip
  });
}
const formatDigit = (num) => {
  if(num <10 ){
    return '0'+num;
  }else{
    return num;
  }
}
function timestampToTime(timestamp) {
    const date = new Date(timestamp * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
    const Y = date.getFullYear() + '-';
      const M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
      const D = formatDigit(date.getDate()) + ' ';
      const h = formatDigit(date.getHours()) + ':';
      const m = formatDigit(date.getMinutes()) + ':';
      const sec = date.getSeconds();
      const s = sec >= 10 ? sec : `0${sec}`
      return Y+M+D+h+m+s;
}
export {
  showErrorTip,
  showSuccessTip,
  timestampToTime
}