import 'swiper/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
export default {
  name:'carousel',
  props:['data'],
  components:{
    swiper,
    swiperSlide
  },
  data () {
    return {
      clickslideBtn:false,
      swiperOption: {
        autoplay: 2500,
        loop:true,
      }
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper
    }
  },
 methods:{
  slideToPrev(){
    this.clickslideBtn = true;
    this.swiper.slidePrev();
  },
  slideToNext(){
    this.clickslideBtn = true;
    this.swiper.slideNext();
  },
  handleClickSlide(){
    console.log(1,this.clickslideBtn)
    if(this.clickslideBtn === true){
    this.clickslideBtn = false;

      return ;
    }
    const {realIndex} = this.swiper;
    const {url} = this.data[realIndex]
    if(url){
      // location.href = './#/carousel-python'
      location.href=url;
    }
  }
 }
}