import carousel from './components/carousel/index.vue';
import {getBanner} from '../api/index.js';
import {showErrorTip} from '../util/index.js';
export default {
  name:'home',
  components:{
    carousel,
  },
  data(){
    return {
      banners:[],
    }
  },
  async mounted(){
    const res = await getBanner();
    if(res.error !== null){
      showErrorTip(res.data);
    }else{
      this.banners = res.data;
    }
  },
  methods:{
    handleClickImg(){
      window.open('http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802022931');
    }
  }
}