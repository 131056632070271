import {get,post} from '../util/request.js';
import {showErrorTip} from '../util/index.js'

// 获取首页配置枚举字段
const getConfig = async () => {
  const res = await get({
    url:'/api/WebsiteConfigAPI'
  })
  if(res.error){
    showErrorTip(res.data);
  }
  return res;
};

// 搜索列表
const searchList = async (data) => {
  const res = await get({
    url:'/api/PublicScratchProjectAPI?',
    data
  })
  if(res.error){
    showErrorTip(res.data);
  }
  return res;
};

// 登录
const login = async(data) =>{
  const res = await post({
    url:'/api/LoginAPI',
    data
  })
  if(res.error){
    showErrorTip(res.data);
  }
  return res;
}


// 获取首页轮播图
const getBanner = async () => {
  const res = await get({
    url:'/api/BannerAPI'
  })
  if(res.error){
    showErrorTip(res.data);
  }
  return res;
};
// 获取首页展示列表
const getList = async (data) => {
  const res = await get({
    url:'/api/PublicScratchProjectAPI',
    data
  })
  if(res.error){
    showErrorTip(res.data);
  }
  return res;
};


// 获取用户信息
const getUser = async () => {
  const res = await get({
    url:'/api/UserProfileAPI'
  })
  return res;
}

// 登出
const logout = async () => {
  const res = await post({
    url:'/api/LogoutAPI'
  });
  return res;
};
export {
  login,
  getBanner,
  getUser,
  logout,
  getList,
  getConfig,
  searchList
}