import Home from './home/index.vue';
const routes = [
  {
    path:'/',
    component:Home
  },
  {
    path:'/login',
    component:()=>import(/* webpackChunkName: "login" */ './login/index.vue') 
  },
  {
    path:'/carousel-scratch',
    component:()=>import(/* webpackChunkName: "carousel-scratch" */ './carousel-scratch/index.vue') 
  },
  {
    path:'/carousel-c',
    component:()=>import(/* webpackChunkName: "carousel-c" */ './carousel-c/index.vue') 
  },
  {
    path:'/carousel-python',
    component:()=>import(/* webpackChunkName: "carousel-python" */ './carousel-python/index.vue') 
  },
  {
    path:'/carousel-c',
    component:()=>import(/* webpackChunkName: "carousel-c" */ './carousel-c/index.vue') 
  },
  {
    path:'/submission-list',
    component:()=>import(/* webpackChunkName: "submission-list" */ './submission-list/index.vue') 
  },
  { path:'/index',component:()=>import(/* webpackChunkName: "index" */ './index/index.vue') },
  { path:'/user',component:()=>import(/* webpackChunkName: "user" */ './user/index.vue') },
  { path:'/product',component:()=>import(/* webpackChunkName: "product" */ './product/index.vue') },
  { path:'/class',component:()=>import(/* webpackChunkName: "class" */ './class/index.vue') },
  { path:'/course/:id',component:()=>import(/* webpackChunkName: "course" */ './course/index.vue') },
  { path:'/task/:id',component:()=>import(/* webpackChunkName: "task" */ './task/index.vue') },
  { path:'/publish',component:()=>import(/* webpackChunkName: "publish" */ './publish/index.vue') },
  { path:'/product-detail',component:()=>import(/* webpackChunkName: "product-detail" */ './product-detail/index.vue') },
  { path:'/student-record/:id',name:'学情记录',component:()=>import(/* webpackChunkName: "student-record" */ './student-record/index.vue') },
  { path:'/student-task-status/:id',name:'学生作业完成情况列表',component:()=>import(/* webpackChunkName: "student-task-status" */ './student-task-status/index.vue') },
];
export default routes;