import axios from 'axios';
// import {banner,user} from '../mock/index.js';
const parseObjToQuery = (obj) =>{
  const arr = Object.keys(obj).map(key =>{
    return (`${key}=${obj[key]}`);
  });
  return arr.join('&');
}
const request = ({
  baseUrl,
  method
}) => {
  return function({url,data={}}){
    const reqUrl =`${baseUrl}${url}`;
      return new Promise(function(resolve,reject){
        if(method === 'get'){
          let finalUrl = reqUrl;
          const queryStr = parseObjToQuery(data);
          if(queryStr !== ''){
            const sign = reqUrl.indexOf('?')!== -1 ? '&' : '?';
            finalUrl =  `${reqUrl}${sign}${queryStr}`;
          }
          axios.get(finalUrl)
          .then(function(res){
            resolve(res.data)
          })
          .catch(function(err){
            reject(err);
          })
        }
        if(method === 'delete'){
          let finalUrl = reqUrl;
          const queryStr = parseObjToQuery(data);
          if(queryStr !== ''){
            const sign = reqUrl.indexOf('?')!== -1 ? '&' : '?';
            finalUrl =  `${reqUrl}${sign}${queryStr}`;
          }
          axios.delete(finalUrl)
          .then(function(res){
            resolve(res.data)
          })
          .catch(function(err){
            reject(err);
          })
        }
        if(method === 'post'){
          let finalUrl = reqUrl;
          axios.post(finalUrl,data)
          .then(function(res){
            resolve(res.data)
          })
          .catch(function(err){
            reject(err);
          })
        }
        if(method === 'put'){
          let finalUrl = reqUrl;
          axios.put(finalUrl,data)
          .then(function(res){
            resolve(res.data)
          })
          .catch(function(err){
            reject(err);
          })
        }
      })
  }
}
const baseUrl = '';
// const baseUrl = 'http://117.51.158.15:8010';
const get = request({
  baseUrl,
  method:'get'
});
const post = request({
  baseUrl,
  method:'post'
});
const put = request({
  baseUrl,
  method:'put'
});
const deleteReq = request({
  baseUrl,
  method:'delete'
});
export {
  get,
  post,
  put,
  deleteReq
}
