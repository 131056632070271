import Vue from 'vue'
import VueRouter from 'vue-router';
import App from './App.vue'
import ElementUI from 'element-ui'
import './assets/reset.less';
import './assets/element-variables.scss';
import routes from './routes.js';
// const routerPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location) {
//   return routerPush.call(this, location).catch(error=> error)
// }
Vue.use(VueRouter);
Vue.use(ElementUI)

Vue.config.productionTip = false;

const router = new VueRouter({
  routes,
  mode:'hash'
});

new Vue({
  router,
  // render () {
  render: h => h(App),

    // return <router-view />;
  // }
}).$mount('#app')
