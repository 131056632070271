import { getUser,logout } from '../api/index.js';
import {showErrorTip} from '../util/index.js';
class User {
  constructor(){
    this.userinfo = {};
    this.isLogin = false;
  }

  async getUser(){
    const res = await getUser();
    if(res.error !== null){
      if(res.error === 'login-required'){
        this.isLogin = false;
        // window.open('/api/LoginCallBackAPI?code=1');
        // this.$router.push('/api/LoginCallBackAPI?code=1');
      }
    }else{
      this.isLogin = true;
      this.userinfo = res.data;
    }
  }
  login(){

    // window.open('./api/LoginCallBackAPI?code=1');
  }
  async logout(){
    const res = await logout();
    if(res.error === null){
      this.userinfo = {};
      this.isLogin = false;
    }else{
      showErrorTip(res.data);
    }
  }
}
export default new User();