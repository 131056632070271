<template>
  <div class="app">
    <div class="app-header">
      <div class="app-menu">
        <div class="app-menu-main">
          <img class="app-logo" src="./assets/app_logo.jpg"/>
          <el-menu :default-active="activeIndex" mode="horizontal" @select="handleSelect" background-color="#99cc33">
            <el-menu-item index="/">首页</el-menu-item>
            <el-menu-item index="/index">创意展示</el-menu-item>
            <el-menu-item index="2">编程创作</el-menu-item>
            <el-menu-item index="3">课程中心</el-menu-item>
            <el-menu-item index="4">个人中心</el-menu-item>          
          </el-menu>
        </div>
        <div class="app-login" @click="login" v-if="user.isLogin === false">登录</div>
        <div class="app-logout" v-if="user.isLogin === true">
          <img :src="user.userinfo.avatar" @click="clickImg" >
          <div class="app-user-name">{{user.userinfo.username}}</div>
          <p @click="logout" >登出</p>
        </div>
      </div>
    </div>
    <router-view />
    <!-- <div class="app-footer">
      爱思创有限公司
    </div> -->
  </div>
</template>

<script>
import {Menu,MenuItem} from 'element-ui';
import user from './js/user.js';

export default {
  name: 'App',
  components: {
    elMenu:Menu,
    elMenuItem:MenuItem
  },
  data(){
    return {
      user:user,
    }
  },
  computed:{
    activeIndex(){
      return this.$route.path;
    }
  }, 
  async mounted(){
    user.getUser();
  },
  methods:{
    handleSelect(index){
      if(index === '2'){
        window.open('./scratch3/index.html');
        return;
      }
      if(index === '3' || index==='4'){
        if(user.isLogin === false){
          this.login();
          return;
        }else{
          if(index === '3'){
            if(user.userinfo.role==='teacher'||user.userinfo.role==='class_mgmt'){
              this.$router.push(`/class`);          
            }else{
            this.$router.push(`/product`);          
            }
            return;
          }
          if(index === '4'){
            this.$router.push('/user');
          }
        }
        return;
      }
      this.$router.push(`${index}`);
    },
    login(){
      if(this.$route.path==='/login'){
        return;
      }
      this.$router.push(`/login`);      
    },
    logout(){
      user.logout();
    },
    clickImg(){
      if(this.$route.path !=='/user'){
        this.$router.push('/user')        
      }
    }
  }
}
</script>

<style lang="less">
.app{
  position: relative;
  height: 100%;
  &-header{
    background-color: #99cc33;
  }
  &-logo{
    height: 40px;
    margin-right: 64px;
  }
  &-menu{
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    width: 1280px;
    padding:0 20px;
    margin: 0 auto;
    &-main{
      display: flex;
      align-items: center;
    }
  }
  .el-menu{
    background-color: transparent;
  }
  .el-menu.el-menu--horizontal{
    border-bottom: none;
  }
  .el-menu--horizontal>.el-menu-item.is-active{
    background-color: #1890ff;
    color:#fff;
  }
  .el-menu--horizontal>.el-menu-item:not(.is-disabled):focus, .el-menu--horizontal>.el-menu-item:not(.is-disabled):hover, .el-menu--horizontal>.el-submenu .el-submenu__title:hover{
    background-color: #1890ff;
  }
  .el-menu--horizontal .el-menu-item:not(.is-disabled):focus, .el-menu--horizontal .el-menu-item:not(.is-disabled):hover{
    color:#fff;
  }
  &-login,&-logout{
    color:#fff;
    cursor: pointer;
  }
  &-logout{
    display: flex;
    align-items: center;
    img{
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 12px;
    }
    .app-user-name{
      margin-right:5px;
      color:#666;
      font-size:14px;
    }
  }
  // &-footer{
  //   position: fixed;;
  //   bottom: 20px;
  //   left: 50%;
  //   transform: translateX(-50%);
  //   color: #666;
  // }
}
</style>
